import MasterServiceAPI from "../../MasterServiceAPI";
import { addOrder,fetchFeesTypeById } from "./orderPayment";

export const PaymentAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    addOrder: addOrder(build),
    fetchFeesTypeById:fetchFeesTypeById(build)
  }),
  overrideExisting: false,
});

export const { useAddOrderMutation,useLazyFetchFeesTypeByIdQuery } = PaymentAPI;
