import MasterServiceAPI from "../../MasterServiceAPI";

import {
  fetchVesselHistory,
  fetchCrewDetailsByVesselId,
  fetchRegisteredVessels,
  fetchListForSurvey,returnVessel,pullBackVessel
} from "./vessel";

export const CarvingAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchVesselHistory: fetchVesselHistory(build),
    fetchCrewDetailsByVesselId: fetchCrewDetailsByVesselId(build),
    fetchRegisteredVessels: fetchRegisteredVessels(build),
    fetchListForSurvey:fetchListForSurvey(build),
    returnVessel:returnVessel(build),
    pullBackVessel:pullBackVessel(build)

  }),
  overrideExisting: false,
});

export const {
  useLazyFetchVesselHistoryQuery,
  useLazyFetchCrewDetailsByVesselIdQuery,
  useLazyFetchRegisteredVesselsQuery,
  useLazyFetchListForSurveyQuery,
  useReturnVesselMutation,
  usePullBackVesselMutation
} = CarvingAPI;
