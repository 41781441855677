import { pagination } from "../../../utils/constants";
const API_ENDPOINT = "/vessel";


export const fetchVesselHistory = (build) => {
  return build.query({
    query: ({ id, type = null }) => {
      if (type) return `${API_ENDPOINT}/history/${id}?type=${type}`;
      return `${API_ENDPOINT}/history/${id}`;
    },
  });
};

export const fetchCrewDetailsByVesselId = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `${API_ENDPOINT}/crewdetails/${id}`;
      return url;
    },
  });
};

export const fetchRegisteredVessels = (build) =>{
  return build.query({
    query: ({ searchParams, page }) => {
      const params = [];
      if (searchParams) params.push(searchParams);
      if (page) {
        params.push(`page=${page}`);
        params.push(`limit=${pagination.limit}`);
      }

      const url = `${API_ENDPOINT}/list/all/registeredvessels?${params.join("&")}`;
      return url;
    },
  });
}

export const fetchListForSurvey=(build) =>{
  return build.query({
    query: () => {
      const url = `${API_ENDPOINT}/list/forsurvey`;
      return url;
    },
  });
}
export const returnVessel = (build) =>
  build.mutation({
    query: ({id,data}) => ({
      url: `/vessel/return/${id}`,
      method: "post",
      body:data
     }),
     
  });
  export const pullBackVessel = (build) =>
    build.mutation({
      query: ({id,data}) => ({
        url: `/vessel/pullback/${id}`,
        method: "post",
        body:data
       }),
       
    });