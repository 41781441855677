const API_ENDPOINT = "/payment";

export const addOrder = (build) => {
  return build.mutation({
    query: ({ data }) => ({
      url: `${API_ENDPOINT}/order`,
      method: "POST",
      body: data,
    }),
  });
};
export const fetchFeesTypeById = (build) => {
  return build.query({
    query: ({ id }) => {
      const url = `${API_ENDPOINT}/details/byApplication/${id}`;
      return url;
    },
   });
};
