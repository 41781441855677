import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { object, string, array, number, mixed } from "yup";
import { FieldArray, Formik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "../../../components/Button";
import TextBox from "../../../components/TextBox";
import SelectBox from "../../../components/Selectbox";
import CommonPageTitle from "../../../components/CommonPageTitle";
import CheckBox from "../../../components/CheckBox";
import { useLazyFetchPreApprovalTypesQuery } from "../../../services/modules/admin/PreApprovalTypes";
import { useLazyFetchVesselTypesQuery } from "../../../services/modules/admin/vessel";
import {
  useAddYardFromAdminMutation,
  useAddYardMutation,
} from "../../../services/modules/admin/yard";
import style from "../style.module.scss";
import CommonInputTable from "../../../components/CommonInputTable";
import UserService from "../../../services/UserService";
import { millisToMinutesAndSeconds } from "../../../utils/utility";
import { useLazyGetOtpQuery } from "../../../services/modules/otp";
import { useLazyFetchDistrictsQuery } from "../../../services/modules/admin/district";
import CommonModal from "../../../components/CommonModal";
import FileSelector from "../../../components/FileSelector";
import TextBoxLive from "../../../components/TextBoxLive";
import OTPInput from "react-otp-input";
import "./style.scss";
import { uploadFilesInSchema } from "../../../utils/uploadFilesInSchema";
import Loader from "../../../components/Loader";
import Breadcrumb from "../../../components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useLazyFetchStateQuery } from "../../../services/modules/admin/state";

function AddYard(props) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchPreApprovalTypes, { data: preApprovalTypes }] =
    useLazyFetchPreApprovalTypesQuery();
  const [fetchVesselTypes, { data: vesselTypes }] =
    useLazyFetchVesselTypesQuery();
  const [fetchState, { data: stateData, isLoading: stateLoading }] =
    useLazyFetchStateQuery();

  const [addYard] = useAddYardMutation();
  const [addYardFromAdmin] = useAddYardFromAdminMutation();
  const [
    getOtp,
    {
      isSuccess: isGetOtpSuccess,
      data: getOtpData,
      isUninitialized: isGetOtpUninitialized,
    },
  ] = useLazyGetOtpQuery();

  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserProfile();
      if (userDetail) {
        setUserInfo(userDetail);
      }
    };
    getUserInfo();
  }, []);

  const [message, setMessage] = useState({
    title: "",
    isSuccess: true,
    show: false,
  });

  const yardFeatures = [
    { label: "Channel Available", name: "channelAvailable" },
    { label: "Basin Available", name: "basinAvailable" },
    { label: "Slip Rails", name: "slipRails" },
    { label: "Trolly Available", name: "trollyAvailable" },
    { label: "Repair Yard", name: "repairYard" },
    { label: "Electric Supply", name: "electricSupply" },
    { label: "Water Connection", name: "waterConnection" },
    { label: "Sewage Disposal", name: "sewageDisposal" },
    { label: "Garbage Disposal", name: "garbageDisposal" },
    { label: "Oil Reception", name: "oilReception" },
    { label: "Gas Free", name: "gasFree" },
    { label: "Gas Cutting", name: "gasCutting" },
    { label: "Welding Machine", name: "weldingMachin" },
    { label: "Certificate Of Welder", name: "certificateOfWelder" },
    { label: "Road Connectivity", name: "roadConnectivity" },
    { label: "Lathe Machine", name: "laithMachine" },
    { label: "Covered Space", name: "coveredSpace" },
    { label: "Is Watchman", name: "isWatchman" },
    { label: "Paying Overtime", name: "payingOvertime" },
    { label: "Communication", name: "communication" },
    { label: "Record Maintenance", name: "recordMaintenance" },
    { label: "Record Store", name: "recordStore" },
    { label: "Record Spares", name: "recordSpares" },
    { label: "Is Premises", name: "isPremises" },
    { label: "Safety Precaution", name: "safetyPrecaution" },
    { label: "Layup Bert", name: "layupBert" },
    { label: "Black Listed", name: "blackListed" },
    { label: "Works Completed", name: "worksCompleted" },
    { label: "No Of Indian Register", name: "noOfIndianRegister" },
    { label: "Insurance", name: "insurrance" },
    { label: "Fire Fighting", name: "fireFighting" },
    { label: "Pollution Cntrl", name: "pollutionCntrl" },
    { label: "Vessel For Last Yr", name: "vesselForLastYr" },
    { label: "Other", name: "other" },
    { label: "Area Of Yard", name: "areaOfYard" },
  ];

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("form submitted");
    setSubmitting(true);
    setIsSubmitting(true);
    const {
      yardName,
      location,
      ownerName,
      email,
      preApprovalTypeId,
      vesselTypeId,
      year,
      numberOfConstruction,
      signature,
      mobile,
      validFrom,
      validTo,
      yardRegNo,
      employees,
      orders,
      channelAvailable,
      basinAvailable,
      slipRails,
      trollyAvailable,
      repairYard,
      electricSupply,
      waterConnection,
      sewageDisposal,
      garbageDisposal,
      oilReception,
      gasFree,
      gasCutting,
      weldingMachin,
      certificateOfWelder,
      roadConnectivity,
      laithMachine,
      coveredSpace,
      isWatchman,
      payingOvertime,
      communication,
      recordMaintenance,
      recordStore,
      recordSpares,
      isPremises,
      safetyPrecaution,
      layupBert,
      blackListed,
      worksCompleted,
      noOfIndianRegister,
      insurrance,
      fireFighting,
      pollutionCntrl,
      vesselForLastYr,
      other,
      areaOfYard,
      district,
      state,
      otp,
    } = values;
    const postDataCommon = {
      yardName,
      location,
      ownerName,
      email,
      // preApprovalTypeId,
      // vesselTypeId,
      year,
      numberOfConstruction,
      mobile,
      validFrom,
      validTo,
      yardRegNo,
      employees,
      orders,
      channelAvailable,
      basinAvailable,
      slipRails,
      trollyAvailable,
      repairYard,
      electricSupply,
      waterConnection,
      sewageDisposal,
      garbageDisposal,
      oilReception,
      gasFree,
      gasCutting,
      weldingMachin,
      certificateOfWelder,
      roadConnectivity,
      laithMachine,
      coveredSpace,
      isWatchman,
      payingOvertime,
      communication,
      recordMaintenance,
      recordStore,
      recordSpares,
      isPremises,
      safetyPrecaution,
      layupBert,
      blackListed,
      worksCompleted,
      noOfIndianRegister,
      insurrance,
      fireFighting,
      pollutionCntrl,
      vesselForLastYr,
      other,
      areaOfYard,
      district,
      state,
      yardRegNo,
      ...(preApprovalTypeId && { preApprovalTypeId }),
      ...(vesselTypeId && { vesselTypeId }),
    };
    if (props.registrationNeeded) {
      let postData = {
        ...postDataCommon,
        signature: signature,
        otpId: isGetOtpSuccess ? getOtpData.result : "",
        otp: otp,
        uid: userInfo?.id,
      };
      try {
        const uploadedPostData = await uploadFilesInSchema(postData);
        const response = await addYard(uploadedPostData);
        console.log("response", response);

        setMessage({
          title: response?.error
            ? response?.error?.data?.message
            : response?.data?.message,
          show: true,
          isSuccess: !response?.error,
        });
      } catch (error) {
        console.log("response catch error", JSON.stringify(error));
      }
    } else {
      try {
        const response = await addYardFromAdmin(postDataCommon);
        setMessage({
          title: response?.error
            ? response?.error?.data?.message
            : response?.data?.message,
          show: true,
          isSuccess: !response?.error,
        });
      } catch (error) {}
    }

    setSubmitting(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    fetchPreApprovalTypes({ searchParams: "status=true" });
  }, [fetchPreApprovalTypes]);

  useEffect(() => {
    fetchVesselTypes({ searchParams: "status=true" });
  }, [fetchVesselTypes]);

  useEffect(() => {
    fetchState({});
  }, [fetchState]);

  const employeeHead = [
    { heading: "Sl.no", field: "slno" },
    { heading: "Name", field: "name" },
    { heading: "Age", field: "age" },
    { heading: "Address", field: "address" },
    { heading: "Expertise", field: "expertise" },
    { heading: "Years of Experience", field: "yearOfExp" },
  ];

  const employeeHeadFields = [
    {
      name: "name",
      type: "text",
      placeholder: "Enter Name",
      // required: true,
    },
    {
      name: "age",
      type: "text",
      placeholder: "Enter age",
      required: true,
    },
    {
      name: "address",
      type: "text",
      placeholder: "Enter address",
      required: true,
    },
    {
      name: "expertise",
      type: "text",
      placeholder: "Enter expertise",
      required: true,
    },
    {
      name: "yearOfExp",
      type: "text",
      placeholder: "Enter years of experience",
      required: true,
    },
  ];

  const orderHead = [
    { heading: "Sl.no", field: "slno" },
    { heading: "Client Name", field: "clientName" },
    { heading: "Vessel Type", field: "vesselType" },
    { heading: "Consultant", field: "consultant" },
  ];

  const orderHeadFields = [
    {
      name: "clientName",
      type: "text",
      placeholder: "Enter Client Name",
      // required: true,
    },
    {
      name: "vesselType",
      type: "select",
      options: vesselTypes?.result.data ?? [],
      placeholder: "Enter vessel type",
      required: true,
    },
    {
      name: "consultant",
      type: "text",
      placeholder: "Enter consultant",
      required: true,
    },
  ];
  const initialValues = {
    yardName: "",
    location: "",
    ownerName: "",
    email: "",
    preApprovalTypeId: "",
    vesselTypeId: "",
    year: "",
    numberOfConstruction: "",
    signature: "",
    mobile: "",
    validFrom: "",
    validTo: "",
    yardRegNo: "",
    employees: [
      {
        name: "",
        age: "",
        address: "",
        expertise: "",
        yearOfExp: "",
      },
    ],
    orders: [
      {
        clientName: "",
        vesselType: "",
        consultant: "",
      },
    ],
    channelAvailable: false,
    basinAvailable: false,
    slipRails: false,
    trollyAvailable: false,
    repairYard: false,
    electricSupply: false,
    waterConnection: false,
    sewageDisposal: false,
    garbageDisposal: false,
    oilReception: false,
    gasFree: false,
    gasCutting: false,
    weldingMachin: false,
    certificateOfWelder: false,
    roadConnectivity: false,
    laithMachine: false,
    coveredSpace: false,
    isWatchman: false,
    payingOvertime: false,
    communication: false,
    recordMaintenance: false,
    recordStore: false,
    recordSpares: false,
    isPremises: false,
    safetyPrecaution: false,
    layupBert: false,
    blackListed: false,
    worksCompleted: false,
    noOfIndianRegister: false,
    insurrance: false,
    fireFighting: false,
    pollutionCntrl: false,
    vesselForLastYr: false,
    other: false,
    areaOfYard: false,
    otp: "",
    otpId: "",
    district: "",
    state: "",
  };

  const validationSchema = object().shape({
    district: string().required("District is required"),
    state: string().required("State is required"),
    yardName: string()
      .required("Yard Name is required.")
      .matches(
        /^[a-zA-Z0-9\s!@#$%^&*(),.\-+=~`'":;?<>|\/\\[\]{}]+$/,
        "Invalid characters in yard name."
      ),
    location: string()
      .required("Location is required.")
      .matches(/^[a-zA-Z ]+$/, "Only letters are allowed."),
    ownerName: string()
      .required("Owner Name is required.")
      .matches(
        /^[a-zA-Z0-9.,'()@!&-_\s]+$/,
        "Only letters, numbers, and the following special characters are allowed: .,'()@!&-_"
      ),
    mobile: string()
      .required("Phone number is required")
      .matches(/^[6-9]\d{9}$/, "Phone Number is not valid"),
    email: string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$/,
        "Invalid Email Address"
      ),
    preApprovalTypeId: string(),
    vesselTypeId: string(),
    validFrom: string().required("Validity is required"),
    validTo: string().required("Validity is required"),
    year: string()
      .required("Year is required.")
      .matches(/^[0-9-/]+$/, "Invalid Year"),
    numberOfConstruction: string().matches(/^[0-9]+$/, "Invalid number"),
    channelAvailable: string(),
    yardRegNo: string()
      .required("Yard Registration Number is required.")
      .matches(
        /^[a-zA-Z0-9.,'()@!&-_\s]+$/,
        "Only letters, numbers, and the following special characters are allowed: .,'()@!&-_"
      ),

    employees: array()
      .of(
        object().shape({
          name: string()
            // .required("Employee name is required!")
            .matches(/^[a-zA-Z ]+$/, "Only characters are allowed."),
          age: string()
            // .required("Employee age is required!")
            .matches(/^[0-9]+$/, "Only numbers are allowed."),
          address: string()
            // .required("Employee address is required!")
            .matches(
              /^[a-zA-Z0-9.,'()@!&#$-_\s]+$/,
              "Only letters, numbers, and the following special characters are allowed: .,'()@!&#$-_"
            ),
          expertise: string()
            // .required("Employee expertise is required!")
            .matches(
              /^[a-zA-Z0-9.,'()@!&#$-_\s]+$/,
              "Only letters, numbers, and the following special characters are allowed: .,'()@!&#$-_"
            ),
          yearOfExp: string()
            //   .required("Year of experience is required!")
            .matches(/^[0-9]+$/, "Only numbers are allowed."),
        })
      )
      .required()
      .min(1),
    orders: array()
      .of(
        object().shape({
          clientName: string()
            //.required("Client name is required!")
            .matches(/^[a-zA-Z ]+$/, "Only characters are allowed."),
          vesselType: string(),
          consultant: string()
            // .required("Consultant is required!")
            .matches(/^[a-zA-Z ]+$/, "Only characters are allowed."),
        })
      )
      .required()
      .min(1),
  });

  const validationSchemaNew = validationSchema.shape({
    otp: number().required("OTP is required"),
    signature: mixed().required("Signature is required"),
  });

  const getValidationSchema = () => {
    return props.registrationNeeded
      ? validationSchema.concat(validationSchemaNew)
      : validationSchema;
  };

  const [fetchDistricts, { data: districtData }] = useLazyFetchDistrictsQuery();

  const onHandleClickBack = () => {
    if (props.registrationNeeded) {
      UserService.doLogout();
    } else {
      navigate("/list-yard");
    }
  };
  const handleStateChange = (e) => {
    fetchDistricts({ searchParams: `stateId=${e.target.value}` });
  };

  if (isSubmitting) return <Loader />;

  return (
    <Container className="dashboard-container">
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema()}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          isSubmitting,
          handleChange,
          values,
          errors,
          touched,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form className="forms" onSubmit={handleSubmit}>
            <Container>
              {!props.registrationNeeded ? (
                <>
                  <Breadcrumb crumbs={props.crumbs} />
                  <CommonPageTitle
                    title={"Yard"}
                    arrowback={true}
                    handleClickBack={onHandleClickBack}
                  />
                </>
              ) : (
                <CommonPageTitle title={"Yard"} />
              )}

              <CommonModal
                {...message}
                description=""
                buttonText="OK"
                handleClose={() => {
                  setMessage((message) => ({
                    ...message,
                    show: false,
                  }));
                  if (message.isSuccess)
                    if (props.registrationNeeded) props.changeRoutes();
                    else navigate("/list-yard");
                }}
              />

              <Row className="d-flex" style={{ paddingTop: "30px" }}>
                <Col lg={4}>
                  <TextBox
                    id="yardName"
                    label="Yard Name"
                    name="yardName"
                    type="text"
                    required={true}
                    onChange={handleChange}
                    value={values.yardName}
                    onBlur={handleBlur}
                    error={errors.yardName}
                    touched={touched.yardName}
                  />
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Location"
                    name="location"
                    type="text"
                    required={true}
                    onChange={handleChange}
                    value={values.location}
                    onBlur={handleBlur}
                    error={errors.location}
                    touched={touched.location}
                  />
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Owner Name"
                    id="ownerName"
                    name="ownerName"
                    type="text"
                    required={true}
                    onChange={handleChange}
                    value={values.ownerName}
                    onBlur={handleBlur}
                    error={errors.ownerName}
                    touched={touched.ownerName}
                  />
                </Col>

                <Col lg={4}>
                  <TextBox
                    label="Email"
                    id="email "
                    name="email"
                    type="text"
                    required={true}
                    onChange={handleChange}
                    value={values.email}
                    validateOnChange={true}
                    onBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                  />
                </Col>
                <Col lg={4}>
                  <SelectBox
                    label="Pre Approval Type"
                    name="preApprovalTypeId"
                    type="text"
                    options={preApprovalTypes?.result.data ?? []}
                    isIdSelect={true}
                    // isRequired={true}
                    onChange={handleChange}
                    validateOnChange={true}
                    onBlur={handleBlur}
                    isTouched={touched.preApprovalTypeId}
                    value={values.preApprovalTypeId}
                    error={errors.preApprovalTypeId}
                  ></SelectBox>
                </Col>
                <Col lg={4}>
                  <SelectBox
                    label="Vessel Type"
                    name="vesselTypeId"
                    type="text"
                    options={vesselTypes?.result.data ?? []}
                    isIdSelect={true}
                    // isRequired={true}
                    onChange={handleChange}
                    validateOnChange={true}
                    onBlur={handleBlur}
                    isTouched={touched.vesselTypeId}
                    value={values.vesselTypeId}
                    error={errors.vesselTypeId}
                  ></SelectBox>
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Year"
                    id="year"
                    name="year"
                    type="text"
                    required={true}
                    onChange={handleChange}
                    value={values.year}
                    validateOnChange={true}
                    onBlur={handleBlur}
                    error={errors.year}
                    touched={touched.year}
                  />
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Number of Construction"
                    id="numberOfConstruction"
                    name="numberOfConstruction"
                    type="text"
                    // required={true}
                    onChange={handleChange}
                    validateOnChange={true}
                    onBlur={handleBlur}
                    touched={touched.numberOfConstruction}
                    value={values.numberOfConstruction}
                    error={errors.numberOfConstruction}
                  />
                </Col>
                <Col lg={4}>
                  <SelectBox
                    label="Select State"
                    name="state"
                    required={true}
                    isRequired={true}
                    onChange={(el) => {
                      handleChange(el);
                      handleStateChange(el);
                    }}
                    value={values.state}
                    isTouched={touched.state}
                    error={errors.state}
                    onBlur={handleBlur}
                    isIdSelect={true}
                    options={stateData?.result?.data ?? []}
                  />
                </Col>
                <Col lg={4}>
                  <SelectBox
                    label="District"
                    id="district"
                    name="district"
                    options={districtData?.result?.data ?? []}
                    value={values.district}
                    isRequired={true}
                    isIdSelect={true}
                    onChange={handleChange}
                    error={errors.district}
                    isTouched={touched.district}
                  />
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Validity of existing Yard from"
                    name="validFrom"
                    type="date"
                    placeholder="Enter"
                    required={true}
                    onChange={handleChange}
                    value={values.validFrom}
                    touched={touched.validFrom}
                    error={errors.validFrom}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Validity of existing Yard to"
                    name="validTo"
                    type="date"
                    placeholder="Enter"
                    required={true}
                    onChange={handleChange}
                    value={values.validTo}
                    touched={touched.validTo}
                    error={errors.validTo}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={4}>
                  <TextBox
                    label="Yard Registration Number"
                    name="yardRegNo"
                    type="text"
                    placeholder="Enter"
                    required={true}
                    onChange={handleChange}
                    value={values.yardRegNo}
                    touched={touched.yardRegNo}
                    error={errors.yardRegNo}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={4}>
                  <TextBox
                    label="Phone Number"
                    id="mobile"
                    name="mobile"
                    type="text"
                    required={true}
                    onChange={handleChange}
                    value={values.mobile}
                    // validateOnChange={true}
                    // onBlur={handleBlur}
                    error={errors.mobile}
                    touched={touched.mobile}
                  />
                </Col>
                {props.registrationNeeded && (
                  <>
                    <OtpButton
                      mobile={values.mobile}
                      getOtp={getOtp}
                      error={errors.mobile}
                    />

                    <Col lg={4}>
                      <label className="mt-2 required-indicator"> OTP </label>
                      <div className="otp-container-yard">
                        <OTPInput
                          value={values.otp}
                          onChange={(value) => {
                            setFieldValue("otp", value);
                          }}
                          numInputs={6}
                          renderSeparator={
                            <span style={{ marginLeft: "3px" }}></span>
                          }
                          renderInput={(props) => (
                            <input
                              {...props}
                              disabled={
                                !values?.mobile ||
                                errors?.mobile ||
                                isGetOtpUninitialized
                              }
                            />
                          )}
                          inputStyle="inputStyle"
                        />
                      </div>
                    </Col>

                    <Col lg={4} className="div-text mt-2">
                      <FileSelector
                        label={"Upload Signature"}
                        name={"signature"}
                        id={"signature"}
                        onBlur={handleBlur}
                        touched={touched.signature}
                        required={true}
                        error={errors.signature}
                        onChange={(e) => {
                          if (e.target.files && e.target.files.length === 1) {
                            setFieldValue("signature", e.target.files[0]);
                          }
                        }}
                        accept="image/*"
                      />
                    </Col>
                  </>
                )}

                <FieldArray name="employees">
                  {({ remove, push }) => (
                    <Col md={12} className="my-4">
                      {
                        <CommonInputTable
                          fieldName={"employees"}
                          rowHead={employeeHead}
                          values={values.employees ?? []}
                          fields={employeeHeadFields}
                          onDelete={remove}
                          onChange={handleChange}
                          validation={{
                            errors: errors,
                            touched: touched,
                          }}
                          required={false}
                        />
                      }
                      <Col>
                        <button
                          type="button"
                          className={`py-2 ${style["link-button"]}`}
                          onClick={() =>
                            push({ ...initialValues.employees[0] })
                          }
                        >
                          + Add employee
                        </button>
                      </Col>
                    </Col>
                  )}
                </FieldArray>
                <FieldArray name="orders">
                  {({ remove, push }) => (
                    <Col md={12} className="my-4">
                      {
                        <CommonInputTable
                          fieldName={"orders"}
                          rowHead={orderHead}
                          values={values.orders ?? []}
                          fields={orderHeadFields}
                          onDelete={remove}
                          onChange={handleChange}
                          validation={{
                            errors: errors,
                            touched: touched,
                          }}
                          required={false}
                        />
                      }
                      <Col>
                        <button
                          type="button"
                          className={`py-2 ${style["link-button"]}`}
                          onClick={() => push({ ...initialValues.orders[0] })}
                        >
                          + Add order
                        </button>
                      </Col>
                    </Col>
                  )}
                </FieldArray>
                <label>Please Select Facility of the Yard:</label>
                <Col className="mt-2 w-100">
                  <Row className="flex">
                    {yardFeatures.map(({ label, name }, key) => {
                      return (
                        <Col md={4} key={key} className="py-2">
                          <CheckBox
                            label={label}
                            id={name}
                            name={name}
                            value={values[name]}
                            onChange={handleChange}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Container>

            <Container className="container">
              <Row className="button-container">
                <Col className="btn-col address-button d-flex justify-content-center">
                  <Button
                    label="Cancel"
                    className="btn-sucess float-right btn-outline"
                    type="button"
                    onClick={() => {
                      if (props.registrationNeeded) {
                        UserService.doLogout();
                        localStorage.clear();
                      } else {
                        navigate("/list-yard");
                      }
                    }}
                  />
                  <Button
                    label="Submit"
                    type="submit"
                    className="btn-sucess float-right btn-default"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  />
                </Col>
              </Row>
            </Container>
          </form>
        )}
      </Formik>
    </Container>
  );
}

function OtpButton({ mobile, getOtp, error }) {
  const OTP_TIMER = 2 * 60 * 1000;
  const [otpTime, setOtpTime] = useState(0);

  useEffect(() => {
    if (otpTime > 0) {
      const timerId = setInterval(() => {
        setOtpTime((prev) => prev - 1000);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [otpTime]);

  return (
    <Col lg={4}>
      <TextBoxLive
        isDisabled={otpTime !== 0 || !mobile || error}
        value={
          otpTime === 0
            ? "Send OTP"
            : `Resend otp in ${millisToMinutesAndSeconds(otpTime)}`
        }
        label="&nbsp;"
        type="button"
        onClick={() => {
          if (!mobile) return;
          getOtp({
            data: {
              phone: mobile,
            },
          }).then((_) => {
            setOtpTime(OTP_TIMER);
          });
        }}
      />
    </Col>
  );
}

export default AddYard;
