import MasterServiceAPI from "../../../MasterServiceAPI";
import {
  fetchVessel,
  fetchVesselbyId,
  addVessel,
  updateVessel,
  deleteVessel,
  fetchVesselAdditionalDetailsbyId,
  addVesselAdditionalDetails,
  initiatePreapprovalProcess,
  fetchSurveyApplication,
  updateVesselAdditionalDetail,
  addPreApprovalProcess,
  deleteVesselAdditionalDetails,
  fetchPreApprovalProcess,
  deletePreApprovalProcess,
} from "./PreapprovalRequest";

export const PreapprovalAPI = MasterServiceAPI.injectEndpoints({
  endpoints: (build) => ({
    fetchVessel: fetchVessel(build),
    fetchVesselbyId: fetchVesselbyId(build),
    addVessel: addVessel(build),
    updateVessel: updateVessel(build),
    deleteVessel: deleteVessel(build),
    fetchVesselAdditionalDetailsbyId: fetchVesselAdditionalDetailsbyId(build),
    addVesselAdditionalDetails: addVesselAdditionalDetails(build),
    initiatePreapprovalProcess: initiatePreapprovalProcess(build),
    fetchSurveyApplication: fetchSurveyApplication(build),
    updateVesselAdditionalDetail: updateVesselAdditionalDetail(build),
    addPreApprovalProcess: addPreApprovalProcess(build),
    deleteVesselAdditionalDetails: deleteVesselAdditionalDetails(build),
    fetchPreApprovalProcess: fetchPreApprovalProcess(build),
    deletePreApprovalProcess: deletePreApprovalProcess(build),

    // fetchVessalAdditionaldetailsbyId:fetchVessalAdditionaldetailsbyId(build)
  }),
  overrideExisting: false,
});

export const {
  useLazyFetchVesselQuery,
  useLazyFetchVesselbyIdQuery,
  useAddVesselMutation,
  useUpdateVesselMutation,
  useUpdateVesselAdditionalDetailMutation,
  useDeleteVesselMutation,
  useLazyFetchVesselAdditionalDetailsbyIdQuery,
  useAddVesselAdditionalDetailsMutation,
  useInitiatePreapprovalProcessMutation,
  useLazyFetchSurveyApplicationQuery,
  useAddPreApprovalProcessMutation,
  useDeleteVesselAdditionalDetailsMutation,
  useLazyFetchPreApprovalProcessQuery,
  useDeletePreApprovalProcessMutation,
} = PreapprovalAPI;
